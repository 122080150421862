<template>
  <div class="">
    <trac-loading v-if="isLoading" />

    <main class="ml-12">
      <trac-back-button>All Counts</trac-back-button>
      <div class="flex flex-row mt-12 mb-8 items-end justify-between">
        <div class="flex-col">
          <h2>Inventory Control</h2>
          <h3 class="font-bold mt-4 text-xl">New Count</h3>
        </div>
        <div class="flex-col">
          <div class="flex flex-row gap-5">
            <trac-button
              @button-clicked="gotoStartCountScreen"
              :disabled="!validCountData"
              class="uppercase"
              >Start Count</trac-button
            >
          </div>
        </div>
      </div>
      <div class="px-8 py-8 bg-white rounded-lg big-shadow">
        <trac-input
          v-model="countData.name"
          class="w-4/12"
          placeholder="Count Name"
        ></trac-input>
        <div class="mt-10 mb-8">
          <trac-custom-header>
            <template slot="leading">Stock</template>
            <template slot="trailing"> Details</template>
          </trac-custom-header>
        </div>
        <div class="border rounded p-3 w-6/12 flex flex-row justify-between">
          <trac-dropdown-exteneded
            @optionSelected="selectOption($event)"
            placement="left"
            :options="stores"
            :selector="
              countData.selectedStore
                ? countData.selectedStore.name
                : 'Select Store'
            "
            :neededProperty="'name'"
            class="my-6"
          >
          </trac-dropdown-exteneded>
        </div>
        <div
          @click="gotoCategoryScreen"
          class="mt-5 cursor-pointer border rounded p-3 w-6/12 flex flex-row justify-between items-center"
        >
          <span class="text-xs">Select item category</span>
          <span class="text-xs ml-auto mr-4 text-primaryBlue">{{
            countData.selected_categories.length > 0
              ? countData.selected_categories.length + " " + "categor(ies)"
              : ""
          }}</span>
          <img src="../../../assets/svg/Path_right.svg" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
export default {
  name: "NewCount",
  data() {
    return {
      isLoading: false,
      stores: null,
      countData: {
        from: "New Count",
        name: "",
        selected_categories: [],
        selectedStore: null,
      },
    };
  },
  async created() {
    this.isLoading = true;
    this.setupCountData();
    this.fetchAllStores();
    this.isLoading = false;
  },
  computed: {
    validCountData() {
      return (
        this.countData.name.length > 0 &&
        this.countData.selected_categories.length > 0 &&
        this.countData.selectedStore
      );
    },
  },
  methods: {
    gotoStartCountScreen() {
      SAVE_LOCAL_DB_DATA("stock-count-data", this.countData);
      this.$router.push({ name: "SubmitCount" });
    },
    setupCountData() {
      if (GET_LOCAL_DB_DATA("stock-count-data")) {
        this.countData = GET_LOCAL_DB_DATA("stock-count-data");
      }
    },
    gotoCategoryScreen() {
      SAVE_LOCAL_DB_DATA("stock-count-data", this.countData);
      this.$router.push({ name: "CategoryCount" });
    },
    selectOption(option) {
      this.countData.selectedStore = option;
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
    },
  },
};
</script>

<style scoped></style>
